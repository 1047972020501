import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import kemi from '../../images/kemi.jpg';
import { COLOR_CORAL } from '../../utils/constants';
import SolutionCard from '../SolutionCard';

const useStyles = makeStyles({
  container: {
    justifyContent: 'space-between',
    borderBottom: '1px solid grey',
    marginTop: 80,
    '@media (max-width:600px)': {
      flexDirection: 'column-reverse',
      marginTop: 40,
    },
  },
  imageGrid: {
    padding: '20px 0px 40px 100px',
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 0px 20px 0px',
      borderBottom: 'none',
    },
  },
  textGrid: {
    padding: '20px 0px 40px 100px',
    maxWidth: 720,
    '@media (max-width:1024px)': {
      padding: '20px 0px 40px 80px',
    },
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
      borderBottom: 'none',
    },
  },
  link: {
    color: COLOR_CORAL,
    textDecoration: 'none',
  },
  solutionsContainer: {
    marginTop: 40,
    marginBottom: 40,
    padding: '0px 0px 0px 100px',
    '@media (max-width:1024px)': {
      padding: '0px 0px 0px 70px',
    },
    '@media (max-width:900px)': {
      padding: '0px 0px 0px 50px',
    },
    '@media (max-width:600px)': {
      padding: '0px 20px 0px 20px',
    },
  },
});

export default () => {
  const classes = useStyles();
  const data = useStaticQuery(query).contentfulOrderedList.items;

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item sm={6} className={classes.textGrid}>
          <h3 style={{ marginTop: 0 }}>ETIA APPLICATIONS</h3>
          <p>
            ETIA's patented electrically heated screw conveyor - Spirajoule - is
            a universal tool for the heat treatment of bulk products and
            residues. Thanks to its flexibility, we have developed multiple
            applications across various domains of the industry.
          </p>
          <a
            href="http://etia-group.com/"
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            www.etia-group.com
          </a>
        </Grid>
        <Grid item sm={6} className={classes.imageGrid}>
          <img src={kemi} alt="Chemistry" />
        </Grid>
      </Grid>
      <Grid container className={classes.solutionsContainer}>
        {data.map(({ title, description: { description }, link }) => (
          <SolutionCard
            title={title}
            description={description}
            link={link}
            key={title}
          />
        ))}
      </Grid>
    </>
  );
};

const query = graphql`
  query EtiaSolutions {
    contentfulOrderedList(
      listId: { eq: "/solutions?subpage=etia-land-based" }
    ) {
      items {
        ... on ContentfulSolutionCard {
          title
          description {
            description
          }
          link
        }
      }
    }
  }
`;
