import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SwipeableViews from 'react-swipeable-views';
import H1 from '../../components/header1';
import { FirstTab, SecondTab } from '../../components/solutionsTabs';
import { TabMenu, TabPanel } from '../../components/Tabs';
import { SUB_PAGES } from '../../utils/constants';
import { isBrowser } from '../../utils/functions';

const tabTitles = SUB_PAGES.SOLUTIONS.titles;
const { solutionsTabs } = SUB_PAGES.SOLUTIONS;

const getIndexFromPathName = pathname => {
  const index = solutionsTabs.findIndex(
    element => element.tabLink === pathname
  );
  return index !== -1 ? index : 0;
};

const Index = () => {
  const theme = useTheme();
  const location = isBrowser() && window.location.pathname;
  const [selectedTab, setSelectedTab] = useState(
    getIndexFromPathName(location)
  );

  const handleChange = (_, index) => {
    setSelectedTab(index);
    const urlToPush = solutionsTabs[index].tabLink;
    if (isBrowser) {
      window.history.pushState(null, null, urlToPush);
    }
  };

  const handleChangeIndex = index => {
    setSelectedTab(index);
  };

  useEffect(() => {
    if (isBrowser) {
      setSelectedTab(getIndexFromPathName(window.location.pathname));
    }
  });

  return (
    <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Grid container direction="column">
        <Grid item>
          <Container maxWidth="xl">
            <H1 title="SOLUTIONS" />
          </Container>
        </Grid>
        <TabMenu value={selectedTab} onChange={handleChange} tabs={tabTitles} />
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={selectedTab}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            value={solutionsTabs[0].tabValue}
            currentValue={selectedTab}
          >
            <FirstTab />
          </TabPanel>
          <TabPanel
            value={solutionsTabs[1].tabValue}
            currentValue={selectedTab}
          >
            <SecondTab />
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Container>
  );
};

export default Index;
