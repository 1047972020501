import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { COLOR_CORAL } from '../utils/constants';
import { string } from 'prop-types';

const useStyles = makeStyles({
  link: {
    color: COLOR_CORAL,
    textDecoration: 'none',
  },
  bodyText: {
    maxWidth: 260,
    '@media (max-width:600px)': {
      maxWidth: 'none',
    },
  },
  line: {
    display: 'block',
    width: 30,
    height: '2px',
    border: 0,
    borderTop: `4px solid${COLOR_CORAL}`,
    margin: '0.5em 0',
    padding: 0,
  },
  readMore: {
    color: COLOR_CORAL,
    paddingBottom: 20,
  },
  solutionTitle: {
    paddingRight: 10,
    '@media (max-width:1100px) and (min-width:960px)': {
      paddingRight: 2,
    },
  },
});

const SolutionCard = ({ title, description, link }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item key={title} xs={12} sm={2}>
        <h4 className={classes.solutionTitle}>{title}</h4>
        <hr className={classes.line} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <p className={classes.bodyText}>{description}</p>
        {link && (
          <a
            href={link}
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className={classes.readMore}>Read more</p>
          </a>
        )}
      </Grid>
    </>
  );
};

SolutionCard.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  link: string,
};

SolutionCard.defaultProps = {
  link: null,
};

export default SolutionCard;
